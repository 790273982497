import SdDemandService from '@/biz/admin/services/Sd/SdDemandService'
import SdSupplyService from '@/biz/admin/services/Sd/SdSupplyService'
import { FirstAidKit, FolderAdd, FolderChecked, Tickets, Key } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/sd',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '需求供应'
    },
    children: [
      {
        path: '/sd/demand',
        component: () => import('pk/views/view.vue'),
        meta: {
          title: '需求管理',
          isGroup: true
        },
        children: [
          {
            path: '/sd/demand/info-check',
            component: () => import('@/biz/admin/views/info/info-check/index.vue'),
            meta: {
              title: '信息审核',
              icon: markRaw(FolderChecked),
              query: {
                _model: SdDemandService.modelName
              }
            },
            children: [
              {
                path: '/sd/demand/info-check/form',
                component: () => import('@/biz/admin/views/info/info-check/form.vue'),
                meta: {
                  title: '审核详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/demand/list',
            component: () => import('@/biz/admin/views/sd/demand/index.vue'),
            meta: {
              title: '信息列表',
              icon: markRaw(Tickets)
            },
            children: [
              {
                path: '/sd/demand/list/form',
                component: () => import('@/biz/admin/views/sd/demand/form.vue'),
                meta: {
                  title: '信息详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/demand/info-provide',
            component: () => import('@/biz/admin/views/info/info-provide/index.vue'),
            meta: {
              title: '信息提供',
              icon: markRaw(FolderAdd),
              query: {
                _model: SdDemandService.modelName
              }
            },
            children: [
              {
                path: '/sd/demand/info-provide/form',
                component: () => import('@/biz/admin/views/info/info-provide/form.vue'),
                meta: {
                  title: '提供详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/demand/info-complaint',
            component: () => import('@/biz/admin/views/info/info-complaint/index.vue'),
            meta: {
              title: '信息投诉',
              icon: markRaw(FirstAidKit),
              query: {
                _model: SdDemandService.modelName
              }
            },
            children: [
              {
                path: '/sd/demand/info-complaint/form',
                component: () => import('@/biz/admin/views/info/info-complaint/form.vue'),
                meta: {
                  title: '投诉详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/demand/code',
            component: () => import('@/biz/admin/views/sd/demand/code.vue'),
            meta: {
              title: '随机码管理',
              icon: markRaw(Key)
            }
          }
        ]
      },
      {
        path: '/sd/supply',
        component: () => import('pk/views/view.vue'),
        meta: {
          title: '供应管理',
          isGroup: true
        },
        children: [
          {
            path: '/sd/supply/info-check',
            component: () => import('@/biz/admin/views/info/info-check/index.vue'),
            meta: {
              title: '信息审核',
              icon: markRaw(FolderChecked),
              query: {
                _model: SdSupplyService.modelName
              }
            },
            children: [
              {
                path: '/sd/supply/info-check/form',
                component: () => import('@/biz/admin/views/info/info-check/form.vue'),
                meta: {
                  title: '审核详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/supply/list',
            component: () => import('@/biz/admin/views/sd/supply/index.vue'),
            meta: {
              title: '信息列表',
              icon: markRaw(Tickets)
            },
            children: [
              {
                path: '/sd/supply/list/form',
                component: () => import('@/biz/admin/views/sd/supply/form.vue'),
                meta: {
                  title: '信息详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/supply/info-provide',
            component: () => import('@/biz/admin/views/info/info-provide/index.vue'),
            meta: {
              title: '信息提供',
              icon: markRaw(FolderAdd),
              query: {
                _model: SdSupplyService.modelName
              }
            },
            children: [
              {
                path: '/sd/supply/info-provide/form',
                component: () => import('@/biz/admin/views/info/info-provide/form.vue'),
                meta: {
                  title: '提供详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/sd/supply/info-complaint',
            component: () => import('@/biz/admin/views/info/info-complaint/index.vue'),
            meta: {
              title: '信息投诉',
              icon: markRaw(FirstAidKit),
              query: {
                _model: SdSupplyService.modelName
              }
            },
            children: [
              {
                path: '/sd/supply/info-complaint/form',
                component: () => import('@/biz/admin/views/info/info-complaint/form.vue'),
                meta: {
                  title: '投诉详情',
                  isForm: true
                }
              }
            ]
          }
        ]
      }
    ]
  }
]
